// src/components/UserList.tsx

import axios from 'axios';
import { ReputationProgress } from '../../types/interfaces';

const MAIN_PATH = `${process.env.REACT_APP_BACKEND_URL}/api/player/reputation`

function getResponse<T>(subPath: string): Promise<T> {
    return axios.get<T>(`${MAIN_PATH}${subPath}`, { withCredentials: true })
        .catch(err => {
            if (err.response.status === 401) {
                window.location.href = `/login`
            }
            return err
        })
        .then(res => res?.data)
}

function post<T>(subPath: string, body?: any): Promise<T> {
    return axios.post<T>(`${MAIN_PATH}${subPath}`, body, { withCredentials: true })
        .catch(err => {
            if (err.response.status === 401) {
                window.location.href = `/login`
            }
            return err
        })
        .then(res => res?.data)
}

export function getHighestReputations(): Promise<ReputationProgress[]> {
    return getResponse<ReputationProgress[]>('/list/highest')
}

export function getReputations(): Promise<ReputationProgress[]> {
    return getResponse<ReputationProgress[]>('/list')
}

export function triggerImport(): Promise<void> {
    return post<void>('/import')
}
