import { Link } from "react-router-dom"
import "./Navbar.css"
import { triggerImport } from "./api/PlayerDataApi";
import { useState } from "react";

function Navbar() {
    const [loading, setLoading] = useState(false);

    const handleImport = async () => {
        setLoading(true);
        try {
            console.log("start loading")
            await triggerImport();
        } finally {
            console.log("finish loading")
            setLoading(false);
        }
    };

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <Link to="/">MyApp</Link>
            </div>
            <ul className="navbar-links">
                <li><Link to="/list/faction">Factions</Link></li>
                <li><Link to="/list/reputation">Reputation</Link></li>
                <li><Link to="/list/reputation/best">Highest Reputation</Link></li>
                <li>
                    <button onClick={handleImport} disabled={loading} className="navbar-link-button fixed-width-button">
                        {loading ? <span className="loading-spinner"></span> : "Import"}
                    </button>
                </li>
            </ul>
        </nav>
    );
}

export default Navbar;