// src/components/UserList.tsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './FactionList.css'
import BooleanFilterCheckbox from './BooleanFilterCheckBox';
import { ReputationProgress, Column, Progress } from '../types/interfaces';
import GenericTable from './table/GenericTable';
import { getHighestReputations } from './api/PlayerDataApi';


const BestValueFactionList: React.FC = () => {
  const [progresses, setProgresses] = useState<ReputationProgress[]>([]);  // State to store the list of users
  const [loading, setLoading] = useState<boolean>(true);  // Loading state
  const [error, setError] = useState<string | null>(null);  // Error state
  const [filterExalted, setFilterExalted] = useState<boolean>(false);

  // Fetch the user list when the component mounts
  useEffect(() => {
    const fetchFactions = async () => {
      try {
        const response = await getHighestReputations()
        setProgresses(response);  // Set the fetched data to the state
      } catch (err) {
        setError("There was an error fetching the factions");
      } finally {
        setLoading(false);  // Set loading to false after data is fetched
      }
    };
    fetchFactions();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  // Define the columns to display for users
  const progressColumns: Column<ReputationProgress>[] = [
    { getValue: item => item.faction.name, label: 'Name' },
    { getValue: item => item.progresses[0].charName, label: 'Character' },
    { getValue: item => item.progresses[0].realm, label: 'Realm' },
    { getValue: item => item.progresses[0].overallProgress, label: 'Progress' },
    { getValue: item => item.progresses[0].overallLeft, label: 'Left' },
  ];

  const filteredProgresses = filterExalted ? progresses.filter(progress => progress.progresses[0].overallLeft > 0) : progresses

  return (
    <div>
      <h2 className="text-3xl font-bold mb-4">Reputation List</h2>
      <BooleanFilterCheckbox labelText='Exclude finished factions' onFilterChange={setFilterExalted} />
      <GenericTable data={filteredProgresses} getId={item => item.faction.uuid} columns={progressColumns} />
    </div>
  );
};

export default BestValueFactionList;
